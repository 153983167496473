import { FC, lazy, Suspense, useContext, useEffect } from 'react'
import './App.css'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import { GlobalStyle } from './global'
import { ThemeContextProvider } from './store/ThemeContext'
import { ToastContextProvider } from './services/toast/ToastContext'
import AxiosProtectedInterceptor from './composites/interceptor/AxiosProtectedInterceptor'
import SearchContext from './services/search/SearchContext'
import ErrorPage from './pages/errorPage/ErrorPage'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { menuList } from './composites/sidebar/SidebarData'

const HomePage = lazy(() => import('./pages/HomePage'))
const DashboardPage = lazy(() => import('./pages/DashboardPage'))

const ErrorComponent = (error: FallbackProps) => {
  return <ErrorPage error={error} />
}

const AppRoutes: FC = () => {
  const { setSelectedTab, setAccountDataList } = useContext(SearchContext)
  const navigate = useNavigate()
  const errorCode = {
    error: {
      response: {
        status: 404,
      },
    },
  }
  useEffect(() => {
    if (window.location.pathname === '/') {
      window.location.pathname = '/Statements'
      menuList[0].isClicked = true
      navigate('/Statements')
      setAccountDataList([])
    } else if (window.location.pathname === '/Accounts') {
      menuList.forEach((value) => {
        if (value.name == 'Account & Client Documents' && !value.isClicked) {
          value.isClicked = !value.isClicked
        } else if (value.name != 'Account & Client Documents')
          value.isClicked = false
      })
      menuList[1].isClicked = true
      setSelectedTab('Account & Client Documents')
      navigate('/Accounts')
      setAccountDataList([])
    } else if (window.location.pathname === '/DraftDocuments') {
      menuList.forEach((value) => {
        if (value.name == 'Draft Documents' && !value.isClicked) {
          value.isClicked = !value.isClicked
        } else if (value.name != 'Draft Documents') value.isClicked = false
      })
      menuList[2].isClicked = true
      setSelectedTab('Draft Documents')
      navigate('/DraftDocuments')
      setAccountDataList([])
    }
  }, [])

  return (
    <>
      <ThemeContextProvider>
        <ToastContextProvider>
          <ErrorBoundary FallbackComponent={(error) => ErrorComponent(error)}>
            <Suspense>
              <GlobalStyle />
              <Routes>
                <Route element={<HomePage />}>
                  <Route path="/" element={<Navigate to="/Statements" />} />
                  <Route path="/Statements" element={<DashboardPage />} />
                  <Route path="/Accounts" element={<DashboardPage />} />
                  <Route path="/DraftDocuments" element={<DashboardPage />} />
                  <Route path="/*" element={<ErrorPage error={errorCode} />} />
                </Route>
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </ToastContextProvider>
      </ThemeContextProvider>
      <AxiosProtectedInterceptor />
    </>
  )
}
export default AppRoutes
