import {
  AccountSearchByNumberPayloadInterface,
  AccountValidationPayloadInterface,
  ClientByIdPayloadInterface,
  ClientSearchByNamePayloadInterface,
  ClientSearchByNameResponseInterface,
  DeleteIdPayloadInterface,
  // DownloadFilePayload,
  DownloadFilePayloadInterface,
  DraftSubmitFilePayloadInterface,
  DraftSubmitFileResponsePayload,
  FileUploadResponseInterface,
  MappingInterface,
  UpdateAccountPayload,
  UserPayloadInterface,
} from './ISearchContext'
import ENDPOINTS from '../httpEndpoints'
import {
  ResponsePropsData,
  SearchTableResponseModel,
  ValidateAccountProps,
} from '../../composites/searchDocument/ISearchDocument'
import axiosProtectedInstance from '../http/axiosService'
import appConfig from '../../settings/appConfig'

const host = appConfig.apiEndPoint

export const clientSearchByNameAPI = (
  payload: ClientSearchByNamePayloadInterface
) => {
  return axiosProtectedInstance.post<ClientSearchByNameResponseInterface[]>(
    host + ENDPOINTS.GET_CLIENT_LIST_BY_NAME,
    payload
  )
}
export const accountSearchByNumberAPI = (
  payload: AccountSearchByNumberPayloadInterface
) => {
  return axiosProtectedInstance.post<[ClientSearchByNameResponseInterface]>(
    host + ENDPOINTS.GET_ACCOUNTS_BY_NUMBER,
    payload
  )
}

export const accountSearchByIdClientId = (
  payload: ClientByIdPayloadInterface
) => {
  const urlEndpoint = ENDPOINTS.GET_ACCOUNT_DETAILS_BY_CLIENTID
  return axiosProtectedInstance.get<ResponsePropsData>(
    host + urlEndpoint.replace('{id}', payload.id.toString())
  )
}

export const accountSearchByIdAPI = (
  payload: ClientByIdPayloadInterface,
  roleName: string
) => {
  let urlEndpoint = ENDPOINTS.GET_ACCOUNT_DETAILS_BY_ID
  if (roleName !== 'DM-CORRESPONDENT') {
    urlEndpoint = ENDPOINTS.GET_ACCOUNT_DETAILS_BY_ID_OLD
  }
  return axiosProtectedInstance.get<ResponsePropsData>(
    host +
      urlEndpoint
        .replace('{id}', payload.id.toString())
        .replace('{mailId}', payload.mailId ? payload.mailId : '')
        .replace('{PageNumber}', payload.PageNumber.toString())
        .replace('{PageSize}', payload.PageSize.toString())
  )
}
export const documentsByDraftRecordsAPI = () => {
  const urlEndpoint = ENDPOINTS.DOCUMENTS_BY_DRAFT_RECORDS
  return axiosProtectedInstance.get<SearchTableResponseModel[]>(
    host + urlEndpoint
  )
}

export const accountSearchByIdEntity = (
  payload: ClientByIdPayloadInterface
) => {
  const urlEndpoint = ENDPOINTS.GET_ACCOUNT_DETAILS_BY_ENTITY
  return axiosProtectedInstance.get<ResponsePropsData>(
    host +
      urlEndpoint
        .replace('{id}', payload.id.toString())
        .replace('{PageNumber}', payload.PageNumber.toString())
        .replace('{PageSize}', payload.PageSize.toString())
  )
}

export const accountSearchByIdRepCode = (
  payload: ClientByIdPayloadInterface,
  roleName: string
) => {
  let urlEndpoint = ENDPOINTS.GET_ACCOUNT_DETAILS_BY_REP_CODE
  if (roleName !== 'DM-CORRESPONDENT') {
    urlEndpoint = ENDPOINTS.GET_ACCOUNT_DETAILS_BY_REP_CODE_OLD
  }
  return axiosProtectedInstance.get<ResponsePropsData>(
    host +
      urlEndpoint
        .replace('{id}', payload.id.toString())
        .replace('{mailId}', payload.mailId ? payload.mailId : '')
        .replace('{PageNumber}', payload.PageNumber.toString())
        .replace('{PageSize}', payload.PageSize.toString())
  )
}

export const accountSearchByIdOfficeCode = (
  payload: ClientByIdPayloadInterface,
  roleName: string
) => {
  let urlEndpoint = ENDPOINTS.GET_ACCOUNT_DETAILS_BY_OFFICE_CODE
  if (roleName !== 'DM-CORRESPONDENT') {
    urlEndpoint = ENDPOINTS.GET_ACCOUNT_DETAILS_BY_OFFICE_CODE_OLD
  }
  return axiosProtectedInstance.get<ResponsePropsData>(
    host +
      urlEndpoint
        .replace('{id}', payload.id.toString())
        .replace('{mailId}', payload.mailId ? payload.mailId : '')
        .replace('{PageNumber}', payload.PageNumber.toString())
        .replace('{PageSize}', payload.PageSize.toString())
  )
}

export const getDropdownSearchAPI = () => {
  return axiosProtectedInstance.get<MappingInterface>(
    host + ENDPOINTS.GET_DROPDOWNLIST
  )
}

export const fileUploadRequest = (payload: FormData) => {
  return axiosProtectedInstance.post<FileUploadResponseInterface>(
    host + ENDPOINTS.POST_UPLOAD,
    payload
  )
}

export const getStatusSearchAPI = () => {
  return axiosProtectedInstance.get(host + ENDPOINTS.STATUS_MAPPING)
}

export const accountSearchUpdate = (payload: UpdateAccountPayload) => {
  return axiosProtectedInstance.put(host + ENDPOINTS.UPDATE_ACCOUNT, payload)
}

export const accountNumberValidation = (
  payload: AccountValidationPayloadInterface
) => {
  const urlEndpoint = ENDPOINTS.ACCOUNT_VALIDATE
  return axiosProtectedInstance.get<ValidateAccountProps>(
    host + urlEndpoint.replace('{id}', payload.id.toString())
  )
}

export const downloadFileAPI = (payload: DownloadFilePayloadInterface) => {
  const urlEndpoint = ENDPOINTS.DOWNLOAD_FILE
  return axiosProtectedInstance.get(
    host + urlEndpoint.replace('{id}', payload.Id.toString())
  )
}

export const accountDeleteByIdAPI = (payload: DeleteIdPayloadInterface) => {
  const urlEndpoint = ENDPOINTS.DELETE_ID
  return axiosProtectedInstance.delete(
    host + urlEndpoint.replace('{id}', payload.id.toString())
  )
}

export const getUserMappingDetails = (payload: UserPayloadInterface) => {
  const urlEndpoint = ENDPOINTS.USERS_MAPPING
  return axiosProtectedInstance.get(
    host + urlEndpoint.replace('{id}', payload.Role)
  )
}

export const getUserAccess = (payload: UserPayloadInterface) => {
  const urlEndpoint = ENDPOINTS.USER_ACCESS_MAPPING
  return axiosProtectedInstance.get(
    host + urlEndpoint.replace('{id}', payload.Role)
  )
}

export const getUserEntitlement = (payload: UserPayloadInterface) => {
  const urlEndpoint = ENDPOINTS.USER_ENTITLEMENT_MAPPING
  return axiosProtectedInstance.get(
    host + urlEndpoint.replace('{id}', payload.Role)
  )
}

export const postDraftSubmitFile = (
  payload: DraftSubmitFilePayloadInterface
) => {
  return axiosProtectedInstance.post<DraftSubmitFileResponsePayload>(
    host + ENDPOINTS.SUBMIT_FILE,
    payload
  )
}

export const getDocumentDetailsAPI = (queryString: string) => {
  const hostAndURLEndpoint = host + ENDPOINTS.GET_DOCUMENT_DETAILS + queryString
  return axiosProtectedInstance.get<ResponsePropsData>(hostAndURLEndpoint)
}
