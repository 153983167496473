import React, { createContext, useEffect, useState } from 'react'
import { useAccount, useMsal } from '@azure/msal-react'
import { protectedResources } from '../settings/authConfig'

export interface AuthCtxProviderInterface {
  accessToken: string
  b2cRefreshToken?: () => void
  userRole: string
  userEmail: string
  setUserRole: (val: string) => void
  loginRoles: RolesInterface
  initialLoader: boolean
}

export const AuthContext = createContext<
  AuthCtxProviderInterface | null | undefined
>(undefined)

interface AuthCtxProviderProps {
  children: React.ReactNode | React.ReactNode[]
}

export interface RolesInterface {
  Entitlements: string[]
  OrgRoles: string[]
  SegRoles: string[]
}

interface IDTokenInterface {
  Username: string
  at_hash: string
  aud: string
  auth_time: number
  email: string
  exp: number
  family_name: string
  given_name: string
  iat: number
  idp: string
  iss: string
  name: string
  nbf: number
  nonce: string
  sub: string
  tid: string
  upn: string
  ver: string
  roles: RolesInterface
}

function AuthContextProvider({ children }: AuthCtxProviderProps) {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [accessToken, setAccessToken] = useState<string>('')
  const [userRole, setUserRole] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [loginRoles, setLoginRoles] = useState<RolesInterface>({
    Entitlements: [],
    OrgRoles: [],
    SegRoles: [],
  })
  const [initialLoader, setInitiaLoader] = useState(true)

  useEffect(() => {
    async function b2cAuth() {
      if (account && inProgress === 'none') {
        instance
          .acquireTokenSilent({
            scopes: protectedResources.api.scopes,
            account: account,
          })
          .then((response) => {
            const idTokenDetails = response.idTokenClaims as IDTokenInterface
            if (idTokenDetails?.roles) {
              const getRolesData = JSON.parse(idTokenDetails.roles.toString());
              setLoginRoles(getRolesData);
              setInitiaLoader(false)
              if (getRolesData.Entitlements.length > 0) setUserRole(getRolesData.Entitlements[0])
            } else {
              setInitiaLoader(false)
            }
            setAccessToken(response.accessToken)
            setUserEmail(idTokenDetails.Username)
          }).catch(() => {
            setInitiaLoader(false)
          })
      }
    }
    b2cAuth()
  }, [account, inProgress, instance])

  async function b2cRefreshToken() {
    if (account) {
      const newRequestToken = await instance.acquireTokenSilent({
        scopes: protectedResources.api.scopes,
        account: account,
        forceRefresh: true,
      })
      setAccessToken(newRequestToken.accessToken)
      return newRequestToken.accessToken
    }
  }

  return (
    <AuthContext.Provider
      value={{ accessToken, b2cRefreshToken, userRole, userEmail, setUserRole, loginRoles, initialLoader }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
