import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from './settings/authConfig'
import { MsalProvider } from '@azure/msal-react'
import platform from 'platform'
import { Glue42DesktopFactory, GlueProvider } from '@glue42/react-hooks'
import Glue from '@glue42/desktop'
import GlueWorkspaces from '@glue42/workspaces-api'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
export const msalInstance = new PublicClientApplication(msalConfig)

const AppProvider = () => (
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {platform.name === 'Electron' ? (
        <GlueProvider
          fallback={<h1>Loading...</h1>}
          settings={{
            desktop: {
              factory: Glue as Glue42DesktopFactory,
              config: {
                channels: true,
                appManager: 'full',
                layouts: 'full',
                libraries: [GlueWorkspaces],
              },
            },
          }}
        >
          <AppProvider />
        </GlueProvider>
      ) : (
        <AppProvider />
      )}
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
