export const menuList = [
  {
    name: 'Statements',
    isClicked: false,
    visibility: true,
  },
  {
    name: 'Account & Client Documents',
    isClicked: false,
    visibility: true,
  },
  {
    name: 'Draft Documents',
    isClicked: false,
    visibility: false,
  },
]
