import appConfig from '../../settings/appConfig'
import axiosProtectedInstance from '../http/axiosService'
import ENDPOINTS from '../httpEndpoints'

const host = appConfig.apiEndPoint

export const getRepAccessInfoByUserName = () => {
    const urlEndpoint = ENDPOINTS.GET_REPACCESS_INFO_BY_USERNAME
    return axiosProtectedInstance.get(host + urlEndpoint)
}
